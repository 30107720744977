<template>
    <div class="detail">
        <app-header title="新增发货" :isShowBack="true"></app-header>
        <div style="margin-left: 200px;">
            <el-form ref="form" :model="form" :rules="rules">
                <el-form-item label-width="120px" label="商家名称" prop="name">
                    <el-select style="width: 500px;display: inline-block;" v-model="form.name" placeholder="请选择">
                        <el-option v-for="item in nameList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label-width="120px" label="货柜型号" prop="counterType">
                    <el-select style="width: 500px;display: inline-block;" v-model="form.counterType" placeholder="请选择" @change="counterTypeChaneg(form.counterType)">
                        <el-option v-for="item in counterTypeList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label-width="120px" label="货柜序列号" prop="number">
                    <el-input maxlength="64" style="width: 500px;display: inline-block;" placeholder="请输入"
                        v-model.trim="form.number" @change="numberChange1(form.number)" :disabled="disabled" clearable>
                    </el-input>
                    <div class="size-icon" @click="addRow">
                        <i class="el-icon-circle-plus-outline"></i>
                    </div>
                </el-form-item>
                <div v-for="(item,index) in counterNumberList" :key="index">
                    <el-form-item label-width="120px" label="">
                        <label slot="label"><span style="color:#F56C6C">*</span>&nbsp;&nbsp;货柜序列号</label>
                        <el-input maxlength="64" style="width: 500px;display: inline-block;" placeholder="请输入"
                            v-model.trim="item.number" @change="numberChange(item,index)" clearable :disabled="disabled">
                        </el-input>
                        <div class="size-icon" @click="deleteRow(index)">
                            <i class="el-icon-remove-outline"></i>
                        </div>
                        <div v-if="item.errFlag" class="errTip">请输入货柜序列号</div>

                    </el-form-item>
                </div>
                <div class="tip">支持用扫描枪扫货柜二维码</div>
                <div class="submit-bottun">
                    <el-button type="primary" @click="submit()">提交</el-button>
                </div>

            </el-form>
        </div>

    </div>
</template>
<script>
    import cabinetUtil from "@utils/cabinetUtil.js"
    let validCode = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //支持输入大小写字母，数字，限制1~16位字符
            let reg = /^[A-z0-9]{1,64}$/;
            if (!reg.test(value)) {
                callback(new Error("商品品类编号格式不对"));
            } else {
                callback();
            }
        }
    };
    export default {
        data() {
            return {
                disabled: true,
                counterNumberList: [],
                nameList: [],
                counterTypeList: [],
                form: {
                    counterType: "",
                    name: "",
                    number: '',
                },
                rules: {
                    name: [{
                        required: true,
                        message: "请选择商家名称",
                        trigger: ['change', 'blur'],
                    }],
                    counterType: [{
                        required: true,
                        message: "请选择货柜型号",
                        trigger: ['change', 'blur'],
                        // validator: validInputCode,
                    }],
                    number: [{
                        required: true,
                        message: "请输入货柜序列号",
                        trigger: ['change', 'blur'],
                    }],
                },
                counterTypeStr: '',
            }
        },
        async activated() {
            try {
                // 对表单进行重置
                this.$nextTick(() => {
                    this.$refs["form"].resetFields();
                })
                this.counterNumberList = [];
                this.disabled = true;
                this.counterTypeStr = '';
                this.counterTypeList = await cabinetUtil.getContainerModel();

            } catch (error) {
                console.log(error)
            }


        },
        methods: {
            // 提交
            submit() {
                console.log('counterNumberList---:', this.counterNumberList)
                this.counterNumberList.forEach(item => {
                    if (!item.number) {
                        item.errFlag = true;
                    } else {
                        item.errFlag = false;
                    }
                })
                this.counterNumberList.find(item => {
                    return item.errFlag
                })

                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        let url = '';
                        let message = '发货成功！';
                        let dto = {
                            name: this.form.name,
                            counterType: this.form.counterType,
                        };
                        // WF-L21BD WF-L21BX WF-L22BD WF-L22BX
                        if (this.form.counterType.counterType == 4) {
                            dto.counterType = 6;
                            dto.size = 1;
                        } else if (this.form.counterType.counterType == 5) {
                            dto.counterType = 6;
                            dto.size = 2;
                        } else if (this.form.counterType.counterType == 6) {
                            dto.counterType = 7;
                            dto.size = 1;
                        } else if (this.form.counterType.counterType == 7) {
                            dto.counterType = 7;
                            dto.size = 2;
                        } else {
                            // 其它货柜类型
                            dto.counterType = this.form.counterType.counterType;
                        }
                        this.post(url, dto).then(res => {
                            this.dialogVisibleTrade = false;
                            this.$message({
                                showClose: true,
                                message: message,
                                type: "success"
                            });
                            this.$back();
                        })
                    }
                })

            },
            // 获取货柜型号
            getCounterList() {
                let dto = {
                    pageNum: 1,
                    pageSize: 9999
                };
                this.post("mall-service/category/page/query", dto, {
                    isUseResponse: true
                }).then(res => {
                    var array = res.data.list;
                    array.forEach(item => {
                        item.value = item.id;
                        item.label = item.name;
                    })
                    this.categoryList = array;
                    this.opt.search.forEach(item => {
                        if (item.key == "type") {
                            item.opt.list = array;
                        }
                    })

                })
            },
            addRow() {
                if (this.counterNumberList.length >= 49) {
                    return this.$message({
                        message: '最多支持一次性提交50条货柜信息',
                        type: 'warning'
                    })
                }
                this.counterNumberList.push({
                    number: '',
                    errFlag: false,
                })
            },
            deleteRow(index) {
                this.counterNumberList.splice(index, 1);
            },
            numberChange(item,index) {
                let result = cabinetUtil.getCabinetBySn(item.number);
                if (!result) {
                    this.counterNumberList[index].number = '';
                    return this.$message({
                        message: '无效的序列号！',
                        type: 'warning'
                    })
                }else if(result.model != this.counterTypeStr) {
                    this.counterNumberList[index].number = '';
                    return this.$message({
                        message: '输入的序列号与当前选择的货柜型号不一致！',
                        type: 'warning'
                    }) 
                }
                this.counterNumberList.forEach((it, idx) => {
                    if (it.number && index == idx) {
                        it.errFlag = false;
                    }
                })
                if(item.number && item.number.toUpperCase() == this.form.number.toUpperCase()) {
                    this.counterNumberList[index].number = '';
                    this.counterNumberList[index].errFlag = true;
                    return this.$message({
                        message: '序列号重复！',
                        type: 'warning'
                    })
                }

            },
            numberChange1(data) {
                let result = cabinetUtil.getCabinetBySn(data);
                if (!result) {
                    this.form.number = '';
                    return this.$message({
                        message: '无效的序列号！',
                        type: 'warning'
                    })
                }else if(result.model != this.counterTypeStr) {
                    this.form.number = '';
                    return this.$message({
                        message: '输入的序列号与当前选择的货柜型号不一致！',
                        type: 'warning'
                    }) 
                }
                let repeatNumberFlag = false;
                // 检验序列号是否重复
                if(data && this.counterNumberList.length > 0) {
                    this.counterNumberList.find(item => {
                        return repeatNumberFlag = data.toUpperCase() == item.number.toUpperCase();
                    })
                }
                if(repeatNumberFlag) {
                    this.form.number = '';
                     return this.$message({
                        message: '序列号重复！',
                        type: 'warning'
                    })
                }

            },
            counterTypeChaneg(val) {
                // 初始化数据
                this.form.number = '';
                if(this.counterNumberList.length > 0) {
                    this.counterNumberList.forEach(item => {
                        item.number = '';
                        item.errFlag = true;
                    })
                }
                this.disabled = !val;
                let obj = {
                    counterType: val,
                }
                this.counterTypeStr = cabinetUtil.getContainerTypeName(obj)
            }
        }
    }
</script>
<style lang="scss" scoped>
    .submit-bottun {
        margin-left: 200px;
    }

    .size-icon {
        display: inline-block;
        font-size: 25px;
        position: relative;
        top: 5px;
        padding-left: 6px;
    }

    .errTip {
        color: #F56C6C;
        font-size: 12px;
        height: 0px;
        position: relative;
        top: -7px;
    }

    .tip {
        margin-left: 122px;
        font-size: 14px;
        padding-bottom: 32px;
    }
</style>