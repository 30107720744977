var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail" },
    [
      _c("app-header", { attrs: { title: "新增发货", isShowBack: true } }),
      _c(
        "div",
        { staticStyle: { "margin-left": "200px" } },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "商家名称",
                    prop: "name"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "500px", display: "inline-block" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.name,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name"
                      }
                    },
                    _vm._l(_vm.nameList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "货柜型号",
                    prop: "counterType"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "500px", display: "inline-block" },
                      attrs: { placeholder: "请选择" },
                      on: {
                        change: function($event) {
                          _vm.counterTypeChaneg(_vm.form.counterType)
                        }
                      },
                      model: {
                        value: _vm.form.counterType,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "counterType", $$v)
                        },
                        expression: "form.counterType"
                      }
                    },
                    _vm._l(_vm.counterTypeList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "货柜序列号",
                    prop: "number"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "500px", display: "inline-block" },
                    attrs: {
                      maxlength: "64",
                      placeholder: "请输入",
                      disabled: _vm.disabled,
                      clearable: ""
                    },
                    on: {
                      change: function($event) {
                        _vm.numberChange1(_vm.form.number)
                      }
                    },
                    model: {
                      value: _vm.form.number,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "number",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.number"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "size-icon", on: { click: _vm.addRow } },
                    [_c("i", { staticClass: "el-icon-circle-plus-outline" })]
                  )
                ],
                1
              ),
              _vm._l(_vm.counterNumberList, function(item, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { "label-width": "120px", label: "" } },
                      [
                        _c(
                          "label",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _c("span", { staticStyle: { color: "#F56C6C" } }, [
                              _vm._v("*")
                            ]),
                            _vm._v("  货柜序列号")
                          ]
                        ),
                        _c("el-input", {
                          staticStyle: {
                            width: "500px",
                            display: "inline-block"
                          },
                          attrs: {
                            maxlength: "64",
                            placeholder: "请输入",
                            clearable: "",
                            disabled: _vm.disabled
                          },
                          on: {
                            change: function($event) {
                              _vm.numberChange(item, index)
                            }
                          },
                          model: {
                            value: item.number,
                            callback: function($$v) {
                              _vm.$set(
                                item,
                                "number",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "item.number"
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "size-icon",
                            on: {
                              click: function($event) {
                                _vm.deleteRow(index)
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-remove-outline" })]
                        ),
                        item.errFlag
                          ? _c("div", { staticClass: "errTip" }, [
                              _vm._v("请输入货柜序列号")
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _c("div", { staticClass: "tip" }, [
                _vm._v("支持用扫描枪扫货柜二维码")
              ]),
              _c(
                "div",
                { staticClass: "submit-bottun" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.submit()
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }